import { useContext } from "react";
import TextArea from "../../../components/forms/TextArea";
import Button from "../../../components/misc/Button";
import { FaArrowRotateRight } from "react-icons/fa6";
import { SubmitHandler, useForm } from "react-hook-form";
import { DetailProductContext } from "../DetailProductContainer";
import useServer from "../../../api/useServerMain";

const ProcessedGeneral = () => {

    const { denyRoles } = useServer();

    const { product, updateProduct } = useContext(DetailProductContext);

    const { handleSubmit, control } = useForm();

    const onSubmit: SubmitHandler<Record<string, any>> = (data) => {
        const { elaborationSteps } = data;

        const dataToSend = {
            elaborationSteps
        };

        updateProduct!(product?.id, dataToSend);
    };



    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-1 place-content-between"
        >
            <div className="p-7 flex flex-col border border-slate-300 rounded-md h-auto justify-start items-start gap-2">

                <div className="w-full">
                    <TextArea
                        name="elaborationSteps"
                        control={control}
                        defaultValue={product?.elaborationSteps}
                        label="Pasos de elaboración"
                    />
                </div>


            </div>

            <div className=" flex justify-end py-4">
                {
                    denyRoles(["AUDITOR"]) && <Button
                        icon={<FaArrowRotateRight className={`h-5 `} />}
                        name="Actualizar"
                        color="slate-600"
                        type="submit"
                    />
                }

            </div>
        </form>
    )
}

export default ProcessedGeneral
