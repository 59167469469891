/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import Breadcrumb, { PathInterface } from '../../../../components/navigation/Breadcrumb'
import { CubeIcon } from '@heroicons/react/24/outline'
import GenericTable, { DataTableInterface } from '../../../../components/misc/GenericTable';
import Modal from '../../../../components/misc/GenericModal';
import GrossSalesByContainersReport from '../../../../reports/GrossSalesByContainersReport';
import ProductInventoryPerContainerReport from '../../../../reports/ProductInventoryPerContainerReport';

const CargoAnalisis = ({ breadcrumb = true }) => {

    const [grossSalesByContainers, setGrossSalesByContainers] = useState<boolean>(false)
    const [productInventoryPerContainer, setProductInventoryPerContainer] = useState<boolean>(false)

    const paths: PathInterface[] = [
        {
            name: "Cargas",
        },
        {
            name: "Análisis",
        },
    ];

    const reportsType = [
        {
            id: 2,
            name: "Venta bruta por contenedores",
        },
        {
            id: 3,
            name: "Inventario de productos por contenedor",
        },

    ];

    const tableData: DataTableInterface[] = [];
    reportsType.map(({ id, name }) => {
        tableData.push({
            rowId: id,
            payload: {
                "Nombre del reporte": name,
            },
        });
    });


    // Row table actions
    const rowAction = (id: number) => {
        if (id === 2) {
            setGrossSalesByContainers(true);
        }
        if (id === 3) {
            setProductInventoryPerContainer(true);
        }

    };

    //Data to dislay in table ---------------------------------------------------------------------------
    const tableTitle: string[] = ["Nombre del reporte"];


    return (
        <div>
            {breadcrumb && (
                <Breadcrumb
                    icon={<CubeIcon className="h-7 text-gray-500" />}
                    paths={paths}
                />
            )}

            <GenericTable
                tableData={tableData}
                tableTitles={tableTitle}
                rowAction={rowAction}
            />

            {
                grossSalesByContainers && (
                    <Modal state={grossSalesByContainers} close={() => setGrossSalesByContainers(false)}>
                        <GrossSalesByContainersReport />
                    </Modal>
                )
            }

            {
                productInventoryPerContainer && (
                    <Modal state={productInventoryPerContainer} close={() => setProductInventoryPerContainer(false)}>
                        <ProductInventoryPerContainerReport />
                    </Modal>
                )
            }
        </div>
    )
}

export default CargoAnalisis



