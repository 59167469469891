import React from 'react'
import Button from '../../../components/misc/Button'
import Toggle from '../../../components/forms/Toggle'
import { ConfigUpdate, SendConfigUpdate } from '../../../interfaces/Interfaces';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector } from '../../../store/hooks';
import { convertBoolean } from '../../../utils/translate';
import useServer from '../../../api/useServerMain';

const CargoConfigs = () => {

    const { isLoading, isFetching, EditBussinesAdjustment } = useServer();

    const { business } = useAppSelector((state) => state.init);

    const { control, handleSubmit } = useForm({ mode: "onChange" });

    const onSubmit: SubmitHandler<
        Record<string, string | number | boolean | string[]>
    > = (data) => {

        let configsSend: SendConfigUpdate = {
            configs: [],
        };

        const config: ConfigUpdate[] = [];

        config.push({
            key: "enable_cargo_completed_payment_as_suppliers",
            value: data.enable_cargo_completed_payment_as_suppliers.toString(),
        });

        configsSend = {
            configs: config,
        };
        EditBussinesAdjustment(configsSend);
    };


    const enable_cargo_completed_payment_as_suppliers = convertBoolean(
        business?.configurationsKey?.find(
            (item) => item.key === "enable_cargo_completed_payment_as_suppliers"
        )?.value ?? ""
    );



    return (
        <div className="min-w-full mx-4 px-4 py-5 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-full xl:px-5 xl:pb-20 xl:pt-6 bg-white">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="py-3">
                    <Toggle
                        name="enable_cargo_completed_payment_as_suppliers"
                        control={control}
                        defaultValue={enable_cargo_completed_payment_as_suppliers}
                        title="Tomar el contenedor como pagado por sus pagos al proveedor"
                    />
                </div>


                <div className="pt-6">
                    <div className="max-w-full flow-root  pt-5 pb-4  bg-slate-50 sm:px-6">
                        <div className="float-right">
                            <Button
                                color="slate-600"
                                type="submit"
                                name="Actualizar"
                                loading={isLoading}
                                disabled={isLoading || isFetching}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CargoConfigs
