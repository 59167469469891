import axios from "axios";
import { setKeys } from "../store/slices/sessionSlice";
import { constructURL } from "../utils/helpers";
let store: any;
export const injectMediaStore = (_store: any) => {
  store = _store;
};

const no_authentication = ["/files/"];

const axiosApiInstance = axios.create();

const enviroment = process.env.REACT_APP_API_HOST;

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
      "X-App-Origin": "Tecopos-Admin",
    };

    const session = store.getState().session;

    const finalURL = constructURL(enviroment!, session.processRegion);

    const rute =
      config.url?.split(`${finalURL}${process.env.REACT_APP_VERSION_API}`)[1] ??
      "";

    if (session !== null && !no_authentication.includes(rute)) {
      const keys = session.key;

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${keys.token}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    const session = store.getState().session;

    const finalURL = constructURL(enviroment!, session.processRegion);

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const session = store.getState().session.key;

      if (session) {
        try {
          await axios
            .post(
              `${finalURL}${process.env.REACT_APP_VERSION_API}/security/refresh-token`,
              {
                refresh_token: session.refresh_token,
              }
            )
            .then(async (response) => {
              const new_session = {
                token: response.data.token,
                refresh_token: response.data.refresh_token,
              };
              store.dispatch(setKeys(new_session));

              axiosApiInstance.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.token;
              return axiosApiInstance(originalRequest);
            })
            .catch(async (error) => {
              localStorage.removeItem("session");
              return Promise.reject(error);
            });
        } catch (e) {
          localStorage.removeItem("session");
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

const get = async (path: string, config = {}) => {
  const session = store.getState().session;

  const finalURL = constructURL(enviroment!, session.processRegion);

  const request = {
    url: `${finalURL}${process.env.REACT_APP_VERSION_API}${path}`,
  };

  return axiosApiInstance.get(request.url, config);
};

const post = async (path: string, body: object, config = {}) => {
  const session = store.getState().session;

  const finalURL = constructURL(enviroment!, session.processRegion);

  const request = {
    url: `${finalURL}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "POST",
  };

  return axiosApiInstance.post(request.url, body, config);
};

export default {
  get,
  post,
};
