/* eslint-disable array-callback-return */
import { useState } from 'react'
import Button from '../components/misc/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import useServerReports from '../api/useServerReports';
import Modal from '../components/misc/GenericModal';
import GenericTable, { DataTableInterface } from '../components/misc/GenericTable';
import { printPriceWithCommasAndPeriods } from '../utils/functions';
import { useAppSelector } from '../store/hooks';
import Input from '../components/forms/Input';
import { ProdInventoryByContainerInterface } from '../interfaces/ServerInterfaces';
import { BsFiletypeXlsx } from 'react-icons/bs';
import { BtnActions } from '../components/misc/MultipleActBtn';

const GrossSalesByContainersReport = () => {

    const { business } = useAppSelector(state => state.init)

    const { costCurrency } = business!;

    const [showReportData, setshowReportData] = useState(false)
    const [exportModal, setExportModal] = useState(false);


    const {
        getProdInventoryByContainerReport,
        prodInventoryByContainerData,
        isFetching
    } = useServerReports();

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control } = useForm();

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {
        const { buyedreceiptId } = data

        getProdInventoryByContainerReport(+buyedreceiptId!)

        setshowReportData(true)
    };

    //Data del resultado del reporte ------------------------------------------------------------
    const tableData: DataTableInterface[] = [];
    prodInventoryByContainerData?.map((item) => {
        tableData.push({
            rowId: item?.batchId,
            payload: {
                "Nombre del producto": item?.productName ?? "",
                "Costo unitario": printPriceWithCommasAndPeriods(item?.unitaryCost! ?? 0) + " " + costCurrency,
                "Cantidad inicial": item?.entryQuantity ?? "",
                "Cantidad actual": item?.availableQuantity ?? "",
                "Precio unitario de venta": item?.salesPrices?.map(elem => printPriceWithCommasAndPeriods(elem?.amount!) + " " + (elem?.codeCurrency ?? "")).join(", "),
                "Total vendido": item?.quantitySales ?? 0,
                "Total vendido en moneda de costo": printPriceWithCommasAndPeriods(item?.totalSalesInCostCurrency?.amount ?? 0) + " " + (item?.totalSalesInCostCurrency?.codeCurrency ?? ""),
                "Ganancia": printPriceWithCommasAndPeriods(item?.profit?.amount! ?? 0),
            },
        });
    });


    //Data to dislay in table ---------------------------------------------------------------------------
    const tableTitle: string[] = [
        "Nombre del producto",
        "Costo unitario",
        "Cantidad inicial",
        "Cantidad actual",
        "Precio unitario de venta",
        "Total vendido",
        "Total vendido en moneda de costo",
        "Ganancia",
    ];

    const actions: BtnActions[] = [

        {
            title: "Exportar a excel",
            action: () => setExportModal(true),
            icon: <BsFiletypeXlsx />,
        },

    ];

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Inventario de productos por contenedor
                </h2>
                <div className="flex flex-col gap-2 w-full">

                    <AsyncComboBox
                        className="mt-2"
                        dataQuery={{
                            url: "/administration/buyedreceipt",
                            defaultParams: {
                                page: 1,
                            },
                        }}
                        name="buyedreceiptId"
                        normalizeData={{ id: "id", name: ["name", "operationNumber"], format: "operationNumber name" }}
                        control={control}
                        label="Carga/Contenedor *"
                        rules={{ required: "Campo requerido" }}
                    // callback={(data: ProductInterface[]) => setAsyncProdData(data)}

                    />

                    <div className="w-full flex justify-end gap-3 mt-4">
                        <div>
                            <Button
                                color="slate-600"
                                type="submit"
                                name="Generar"
                            // loading={isFetching}
                            // disabled={isFetching}
                            />
                        </div>
                    </div>
                </div>
            </form>


            {showReportData && (
                <Modal state={showReportData} close={() => setshowReportData(false)} size='l'>

                    <GenericTable
                        tableData={tableData}
                        tableTitles={tableTitle}
                        loading={isFetching}
                        actions={actions}
                    />

                </Modal>
            )}

            {exportModal && (
                <Modal state={exportModal} close={setExportModal}>
                    <ExcelFileExport
                        closeModal={() => setExportModal(false)}
                        prodInventoryByContainerData={prodInventoryByContainerData!}
                    />
                </Modal>
            )}
        </div>
    )
}

export default GrossSalesByContainersReport

interface ExportContainer {
    closeModal: Function;
    prodInventoryByContainerData: ProdInventoryByContainerInterface[];
}

const ExcelFileExport = ({
    closeModal,
    prodInventoryByContainerData,
}: ExportContainer) => {
    const { handleSubmit, control } = useForm();
    const { exportProdInventoryByContainerReport, isFetching } = useServerReports();

    const onSubmit: SubmitHandler<Record<string, string>> = (data) => {

        exportProdInventoryByContainerReport!(prodInventoryByContainerData, data.name, closeModal)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                name="name"
                label="Nombre del archivo"
                placeholder="Nombre del archivo .xlsx"
                control={control}
                rules={{ required: "Debe indicar un nombre para el archivo" }}
            />
            <div className="flex py-2 justify-end">
                <Button
                    type="submit"
                    name="Exportar"
                    color="slate-600"
                    loading={isFetching}
                    disabled={isFetching}
                />
            </div>
        </form>
    );
};