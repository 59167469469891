import { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import Button from '../../../../../components/misc/Button';
import DateInput from '../../../../../components/forms/DateInput';
import useServerReports from '../../../../../api/useServerReports';
import Modal from '../../../../../components/misc/GenericModal';
import GenericTable, { DataTableInterface } from '../../../../../components/misc/GenericTable';
import { printPriceWithCommasAndPeriods } from '../../../../../utils/functions';
import useServerProduct from '../../../../../api/useServerProducts';
import DetailProductContainer from '../../../../products/DetailProductContainer';
import { formatDateForReports } from '../../../../../utils/helpers';
import { BsFiletypeXlsx } from 'react-icons/bs';
import Input from '../../../../../components/forms/Input';
import { exportExcel } from '../../../../../utils/helpers';
import { useAppSelector } from '../../../../../store/hooks';
import Select from '../../../../../components/forms/Select';
import bigDecimal from 'js-big-decimal';



interface InputExpendituresReportInterface {
    setModalInputExpenditures: Dispatch<SetStateAction<boolean>>
}

const InputExpendituresReport = ({
    setModalInputExpenditures
}: InputExpendituresReportInterface) => {

    const { business } = useAppSelector(state => state.init)

    const { getCostAsset, isFetching, costAsset } = useServerReports();
    const {
        getProduct,
        isLoading: isLoadingProduct,
        product,
        updateProduct,
        deleteProduct,
        manageSupplies,
        manageCombos,
        manageManufacturer,
        updateFixedCostState,
        updateAttributeState,
        updateVariationState,
        productRecords,
        getRecordsProduct,
        paginateRecords,
        isLoading,
        isFetchingB } = useServerProduct();
    const [showExpendituresDataModal, setShowExpendituresDataModal] = useState<boolean>(false);
    const [productDetailModal, setProductDetailModal] = useState(false);
    const [dateSelected, setDateSelected] = useState<{ startsAt: string, endsAt: string } | null>(null);
    const [exportModal, setExportModal] = useState(false);

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control } = useForm();

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {
        setDateSelected({
            startsAt: data.startsAt as string,
            endsAt: data.endsAt as string,
        })
        getCostAsset(data, () => setShowExpendituresDataModal(true))
    };


    //Areas ------------------------------------
    const { areas } = useAppSelector((state) => state.nomenclator);

    const stockAreas =
        areas
            ?.filter((area) => area.type === "STOCK")
            .map(({ id, name }) => {
                return { id, name };
            }) || [];

    const fieldValues = useWatch({ control, exact: false });


    const tableTitles = [
        "Nombre del producto",
        "Cantidad utilizada",
        "Costo unitario",
        "Costo total",
    ];

    const tableData: DataTableInterface[] = [];


    costAsset?.forEach((elem: any) => {


        const quantity = new bigDecimal(elem.quantity);
        const averageCost = new bigDecimal(elem.averageCost);

        const totalCost = quantity.multiply(averageCost).getValue()

        tableData.push({
            rowId: elem.productId,
            payload: {
                "Nombre del producto": elem.productName,
                "Cantidad utilizada": elem.quantity,
                "Costo unitario": printPriceWithCommasAndPeriods(elem.averageCost) + " " + business?.costCurrency,
                "Costo total": printPriceWithCommasAndPeriods(totalCost) + " " + business?.costCurrency,
            },
        })
    });


    //Data to display in Table------------------------------------------------------------------
    const action = (id: string) => {
        getProduct(id);
        setProductDetailModal(true);
    };


    //CRUD Variable for detail modal
    const crud = {
        updateProduct,
        deleteProduct,
        manageSupplies,
        manageCombos,
        manageManufacturer,
        updateFixedCostState,
        updateAttributeState,
        updateVariationState,
        productRecords,
        getRecordsProduct,
        paginateRecords,
        isLoading,
        isFetchingB,
        isFetching,
    };

    const actions = [
        {
            title: "Exportar a Excel",
            action: () => setExportModal(true),
            icon: <BsFiletypeXlsx />,
        },
    ];


    const exportFunction = (filename: string) => {

        const dataToExport: Record<string, string | number>[] = [];

        costAsset?.forEach((elem: any) => {


            const quantity = new bigDecimal(elem.quantity);
            const averageCost = new bigDecimal(elem.averageCost);

            const totalCost = quantity.multiply(averageCost).getValue()

            const objectData = {
                "Nombre del producto": elem.productName,
                "Cantidad utilizada": elem.quantity,
                "Costo unitario": printPriceWithCommasAndPeriods(elem.averageCost) + " " + business?.costCurrency,
                "Costo total": printPriceWithCommasAndPeriods(totalCost) + " " + business?.costCurrency,
            };


            dataToExport.push(objectData)
        });


        exportExcel(dataToExport, filename);

    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Gastos por insumos en un rango de fecha
                </h2>
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex w-full justify-start gap-2 items-center">
                        <div className="py-2 w-full">
                            <DateInput
                                name="startsAt"
                                label="Desde *"
                                control={control}
                                rules={{ required: "Este campo es requerido" }}
                                untilToday
                                includeTime={true}
                            />
                        </div>
                        <div className="py-2 w-full">
                            <DateInput
                                name="endsAt"
                                label="Hasta *"
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: (endsAt) =>
                                        new Date(endsAt) >= new Date(fieldValues.startsAt),
                                }}
                                untilToday
                                includeTime={true}
                            />
                        </div>
                    </div>

                    <div className="py-1 col-span-2">
                        <Select
                            name="areaId"
                            data={stockAreas}
                            label="Área *"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>

                    <div className="w-full flex justify-end gap-3 mt-4">
                        <div>
                            <Button
                                color="slate-600"
                                textColor="slate-600"
                                type="submit"
                                name="Cancelar"
                                outline
                                action={() => {
                                    setModalInputExpenditures(false);
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                color="slate-600"
                                type="submit"
                                name="Generar"
                                loading={isFetching}
                                disabled={isFetching}
                            />
                        </div>
                    </div>
                </div>
            </form>

            {
                showExpendituresDataModal && (
                    <Modal
                        state={showExpendituresDataModal}
                        close={() => {
                            setShowExpendituresDataModal(false)
                        }}
                        size="l"
                    >
                        <h2 className="text-xl font-semibold mb-6">
                            Gastos por insumos desde {formatDateForReports(dateSelected?.startsAt!)} hasta {formatDateForReports(dateSelected?.endsAt!)}
                        </h2>
                        <GenericTable
                            tableTitles={tableTitles}
                            tableData={tableData}
                            rowAction={action}
                            actions={actions}
                        />
                    </Modal>
                )
            }


            {productDetailModal && (
                <Modal state={productDetailModal} close={() => setProductDetailModal(false)} size="l">
                    <DetailProductContainer
                        product={product}
                        loading={isLoadingProduct}
                        crud={crud}
                        closeModal={() => setProductDetailModal(false)}
                    />
                </Modal>
            )}

            {exportModal && (
                <Modal state={exportModal} close={setExportModal}>
                    <ExcelFileExport exportFunction={exportFunction} loading={false} />
                </Modal>
            )}
        </>
    )
}


interface ExportContainer {
    exportFunction: Function;
    loading: boolean;
}

const ExcelFileExport = ({ exportFunction, loading }: ExportContainer) => {
    const { handleSubmit, control } = useForm();
    const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
        exportFunction(data.name);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                name="name"
                label="Nombre del archivo"
                placeholder="Nombre del archivo .xlsx"
                control={control}
                rules={{ required: "Debe indicar un nombre para el archivo" }}
            />
            <div className="flex py-2 justify-end">
                <Button
                    type="submit"
                    name="Exportar"
                    color="slate-600"
                    loading={loading}
                    disabled={loading}
                />
            </div>
        </form>
    );
}

export default InputExpendituresReport
