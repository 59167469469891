import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Size = "m" | "l" | "xl";

interface ModalProps {
  state: boolean;
  close: Function;
  size?: Size;
}

export default function Modal({
  state,
  close,
  children,
  size,
}: ModalProps & PropsWithChildren) {
  const [isVisible, setIsVisible] = useState(state);

  // Manejar cambios en `state` para controlar las animaciones de entrada y salida
  useEffect(() => {
    if (state) {
      setIsVisible(true); // Mostrar modal cuando `state` es true
    }
  }, [state]);

  const handleClose = () => {
    // Ejecutar la animación de cierre y luego llamar a `close`
    setIsVisible(false);
    setTimeout(() => {
      close(); // Aquí llamamos a la función `close` del padre después de la animación
    }, 300); // Asegúrate de que coincida con el `leave` en `TransitionChild`
  };

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0" onClose={() => { }}>
        {/* <Dialog as="div" className="fixed z-40 inset-0" onClose={handleClose}> */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

        </TransitionChild>

        <div className="fixed inset-0 z-30 overflow-y-auto scrollbar-none">
          <div className="flex min-h-full justify-center p-4 text-center items-center lg:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-500 transform"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={`relative transform overflow-y-visible rounded-lg bg-gray-100 px-4 pt-5 pb-4 text-left shadow-xl transition-all lg:my-8 ${size === "m"
                  ? "lg:w-1/2"
                  : size === "l"
                    ? "lg:w-4/5"
                    : size === "xl"
                      ? "min-w-[90vw] min-h-[110vh]"
                      : "lg:w-1/3"
                  } lg:h-1/2 lg:max-w-7xl lg:p-6`}
              >
                <div className="fixed right-0 top-2 p-3 lg:block">
                  <button
                    type="button"
                    className="rounded-md bg-gray-100 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="pt-5">{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
