import React, { useContext } from 'react'
import { RegisterDetailsContext } from '../RegisterDetailsContainer'

export const ShippingAndBillingTab = () => {
  const { order } = useContext(RegisterDetailsContext)


  return (
    <div className='overflow-y-auto h-[26rem] px-3'>
        {/*Envío y facturación*/}
        <div className="m-auto grid grid-cols-1 gap-10 py-5">
        {/*Envío*/}
        <div className="flex flex-col gap-1">
          <h5 className="text-gray-600 font-semibold text-lg">Envío:</h5>
            <div>
                {Object.values(order?.shipping ?? {}).filter((itm) => !!itm)
                    .length === 0 ? (
                    <p className="text-2xl">-</p>
                ) : (
                    <div className={'flex flex-col space-y-2 text-sm text-gray-600'}>
                        {(order?.shipping?.firstName || order?.shipping?.lastName) && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Nombre del Receptor:
                                </h6>
                                <p>
                                    {`${
                                        order?.shipping?.firstName ?? ""
                                    } ${order?.shipping?.lastName ?? ""}`}
                                </p>

                            </div>

                        )}
                        {(order?.shipping?.street_1 || order?.shipping?.street_2) && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Dirección:
                                </h6>
                                <p>
                                    {
                                `${
                                    order?.shipping?.street_1 ?? ""
                                } y ${order?.shipping?.street_2 ?? ""}`}
                                </p>
                            </div>
                        )}
                        {order?.shipping?.city && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Localidad:
                                </h6>
                                <p>
                                    {order?.shipping?.city}
                                </p>
                            </div>
                        )}
                        {order?.shipping?.municipality && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Municipio:
                                </h6>
                                <p>
                                    {order?.shipping?.municipality?.name}
                                </p>
                            </div>
                        )}
                        {order?.shipping?.province && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Provincia:
                                </h6>
                                <p>
                                    {order?.shipping?.province?.name}
                                </p>
                            </div>
                        )}
                        {order?.shipping?.country && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Localidad:
                                </h6>
                                <p>
                                    {order?.shipping?.country?.name ?? ""}
                                </p>
                            </div>
                        )}
                        {order?.shipping?.email && (
                            <div className="flex space-x-2">
                                <p className="font-semibold text-gray-950">
                                    Dirección de correo electrónico:
                                </p>
                                <a
                                    href={"mail:" + order?.shipping?.email}
                                    className="text-sm underline text-blue-900"
                                >
                                    {order?.shipping?.email}
                                </a>
                            </div>
                        )}
                        {order?.shipping?.phone && (
                            <div className="flex space-x-2">
                                <p className="font-semibold text-gray-950">Teléfono:</p>
                                <a
                                    href={"tel:" + order.shipping?.phone}
                                    className="text-sm underline text-blue-900 inline"
                                >
                                    {order.shipping?.phone}
                                </a>
                            </div>
                        )}
                        {order?.shippingBy && (
                            <div className={'flex space-x-2'}>
                                <h6 className="font-semibold text-gray-950">
                                    Mensajero:
                                </h6>
                                <p>
                                    {order.shippingBy?.displayName}
                                </p>
                            </div>
                        )}
                        {order?.shippingRegion && (
                            <div className={'flex space-x-2 '}>
                                <h6 className="font-semibold text-gray-950">
                                    Región de envío:
                                </h6>
                                <p>
                                    {`${order.shippingRegion?.name}`}
                                </p>
                            </div>
                        )}
                        {order?.shippingPrice && (
                            <div className={'flex space-x-2 '}>
                                <h6 className="font-semibold text-gray-950">
                                Precio de envío:
                                </h6>
                                <p>
                                    {`${order.shippingPrice?.amount} ${order.shippingPrice?.codeCurrency}`}
                                </p>
                            </div>
                        )}
                    </div>
                        )}
                </div>

            </div>

                {/*Facturación*/}
                <div className="flex flex-col gap-1">
                    <h5 className="text-gray-600 font-semibold text-lg">Facturación:</h5>
          {Object.values(order?.billing ?? {}).filter((itm) => !!itm).length ===
          0 ? (
            <p className="text-2xl">-</p>
          ) : (
            <div  className={'flex flex-col space-y-2 text-sm text-gray-600'}>
              {(order?.billing?.firstName || order?.billing?.lastName) && (
                  <div className={'flex space-x-2'}>
                      <h6 className="font-semibold text-gray-950">
                          Nombre:
                      </h6>
                      <p>
                          {`${
                              order?.billing?.firstName ?? ""
                          } ${order?.billing?.lastName ?? ""}`}
                      </p>
                  </div>
              )}
              {(order?.billing?.street_1 || order?.billing?.street_2) && (
                  <div className={'flex space-x-2'}>
                      <h6 className="font-semibold text-gray-950">
                          Dirección:
                      </h6>
                      <p>
                          {`${
                              order?.billing?.street_1 ?? ""
                          } ${order?.billing?.street_2 ?? ""}`}
                      </p>
                  </div>
              )}
              {order?.billing?.city && (
                  <div className={'flex space-x-2'}>
                      <h6 className="font-semibold text-gray-950">
                          Localidad:
                      </h6>
                      <p>
                          {order?.billing?.city}</p>
                  </div>
              )}
              {order?.billing?.municipality && (
                  <div className={'flex space-x-2'}>
                    <h6 className="font-semibold text-gray-950">
                        Municipio:
                    </h6>
                    <p>
                        {order?.billing?.municipality?.name}
                    </p>
                  </div>
              )}
              {order?.billing?.province && (
                  <div className={'flex space-x-2'}>
                      <h6 className="font-semibold text-gray-950">
                          Provincia:
                      </h6>
                      <p>
                          {order?.billing?.province?.name}
                      </p>
                  </div>
              )}
              {order?.billing?.country && (
                  <div className={'flex space-x-2'}>
                      <h6 className="font-semibold text-gray-950">
                          País:
                      </h6>
                      <p>
                          {order?.billing?.country?.name ?? ""}
                      </p>
                  </div>
              )}
              {order?.billing?.email && (
                  <div className="flex space-x-2">
                      <p className="font-semibold text-gray-950">
                          Dirección de correo electrónico:
                      </p>
                      <a
                        href={"mail:" + order?.billing?.email}
                        className="text-sm underline text-blue-900"
                      >
                          {order.billing?.email}
                      </a>
                  </div>
              )}
              {order?.billing?.phone && (
                <div className="flex space-x-2">
                  <p className="font-semibold text-gray-950">Teléfono:</p>
                  <a
                    href={"tel:" + order.billing?.phone}
                    className="text-sm underline text-blue-900"
                  >
                    {order.billing?.phone}
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
