import DocumentPage from "../components/pdf/DocumentPage";
import {
    AvailableCurrency,
    BatchItemInterface,
    BusinessInterface,
    PriceInvoiceInterface,
    ReceiptBatchInterface,
} from "../interfaces/ServerInterfaces";

import { View, Text, Image as Logo } from "@react-pdf/renderer";
import {
    roundToTwoDecimal,
    roundToTwoDecimalDow,
} from "../utils/functions";
import moment from "moment";
import APIMediaServer from "../api/APIMediaServer";
import { blobToDataURL } from "./helpers/commons";
import {
    translateDispatchStatus,
    translateOrderState,
} from "../utils/translate";
import { formatCurrency, mathOperation } from "../utils/helpers";
import BillingTableTemplate from "../components/pdf/BillingTableTemplate";
import { stylesBillingReportPDF } from "../utils/stylesHelpers";
import { parseISO } from "date-fns";


interface CargoReportPDFInterface {
    receipt: ReceiptBatchInterface | null | undefined;
    business: BusinessInterface | null;
    receiptProducts: BatchItemInterface[]
}

const CargoReportPDF = ({
    receipt,
    business,
    receiptProducts
}: CargoReportPDFInterface) => {

    const invoice_business_name = business?.configurationsKey.find(
        (config) => config.key === "invoice_business_name"
    )?.value;

    const invoice_header = business?.configurationsKey.find(
        (config) => config.key === "invoice_header"
    )?.value;

    const invoice_observations = business?.configurationsKey.find(
        (config) => config.key === "invoice_observations"
    )?.value;

    const billing_show_customer_data =
        business?.configurationsKey.find(
            (config) => config.key === "billing_show_customer_data"
        )?.value === "true";
    const billing_show_business_data =
        business?.configurationsKey.find(
            (config) => config.key === "billing_show_business_data"
        )?.value === "true";
    const billing_show_business_logo =
        business?.configurationsKey.find(
            (config) => config.key === "billing_show_business_logo"
        )?.value === "true";

    const getBusinessLogo = async (logoId: number) => {
        try {
            const response = await APIMediaServer.get("/files/" + logoId, {
                responseType: "blob",
            });
            return await blobToDataURL(response.data);
        } catch (error) {
            // console.error(er ror);
            return require("../assets/image-default.jpg");
        }
    };

    const titlePDF = () => {
        return "Carga"
    };




    return (
        <DocumentPage orientation={"landscape"}>
            <View style={stylesBillingReportPDF.h1}>
                <Text
                    style={{
                        ...stylesBillingReportPDF.h1,
                        ...stylesBillingReportPDF.bottomBorder,
                    }}
                >
                    {titlePDF()}
                </Text>
            </View>
            {["CANCELLED", "REFUNDED"].includes(receipt?.status ?? "") && (
                <View style={stylesBillingReportPDF.statusPage}>
                    <Text style={{ position: "relative", zIndex: 1000 }}>
                        {translateOrderState(receipt?.status ?? "")}
                    </Text>
                </View>
            )}
            {billing_show_business_logo && (
                <View style={stylesBillingReportPDF.pageStart}>
                    <div>
                        <View style={stylesBillingReportPDF.flexCol}>
                            <Logo
                                style={stylesBillingReportPDF.businessLogo}
                                //@ts-ignore
                                src={
                                    business?.logo
                                        ? getBusinessLogo(business?.logo?.id!)
                                        // ? business.logo.src
                                        : require("../assets/image-default.jpg")
                                }
                            />
                        </View>
                    </div>
                </View>
            )}
            <View
                style={{ ...stylesBillingReportPDF.flexCol, gap: 5, width: "100%" }}
            >
                {billing_show_business_data && (
                    <View
                        style={{
                            ...stylesBillingReportPDF.flexCol,
                            width: "50%",
                            marginTop: 10,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Negocio: </Text> */}
                            <Text style={stylesBillingReportPDF.bigTextStyle}>
                                {invoice_business_name || business?.name}
                            </Text>
                        </View>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Negocio: </Text> */}
                            <Text style={stylesBillingReportPDF.textStyle}>
                                {invoice_header}
                            </Text>
                        </View>

                        {business?.email && (
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {/* <Text>Correo electrónico: </Text> */}
                                <Text style={stylesBillingReportPDF.textStyle}>
                                    {business?.email}
                                </Text>
                            </View>
                        )}

                        {business?.phones &&
                            business?.phones.length > 0 &&
                            Number(business?.phones[0]?.number) !== 0 && (
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <Text>Teléfono: </Text> */}
                                    <Text style={stylesBillingReportPDF.textStyle}>
                                        {business?.phones[0].number}
                                    </Text>
                                </View>
                            )}

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Dirección: </Text> */}
                            <Text
                                style={{
                                    ...stylesBillingReportPDF.textStyle,
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                {(business?.address?.street_1
                                    ? business?.address?.street_1 + ", "
                                    : "") +
                                    (business?.address?.street_2
                                        ? business?.address?.street_2 + ", "
                                        : "") +
                                    (business?.address?.city
                                        ? business?.address?.city + ", "
                                        : "") +
                                    (business?.address?.municipality?.name
                                        ? business?.address?.municipality?.name + ", "
                                        : "") +
                                    (business?.address?.province?.name
                                        ? business?.address?.province?.name
                                        : "")}
                            </Text>
                        </View>
                    </View>
                )}

                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    {billing_show_customer_data && (
                        <View style={{ ...stylesBillingReportPDF.flexCol, width: "50%" }}>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text>Datos de la carga {receipt?.name}</Text>
                            </View>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text>Estado: {translateDispatchStatus(receipt?.status)} </Text>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text>Estado de los productos: {translateDispatchStatus(receipt?.productStatus)} </Text>
                            </View>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text>Estado del pago: {translateDispatchStatus(receipt?.paymentStatus)} </Text>
                            </View>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text>
                                    Despachado: {receipt?.isDispatched ? "Si" : "No"}
                                </Text>
                            </View>
                            <>

                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <Text>Número de identificación: </Text> */}
                                    <Text style={stylesBillingReportPDF.textStyle}>
                                        Peso neto: {`${receipt?.netWeight ?? ""} kg`}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <Text>Número de identificación: </Text> */}
                                    <Text style={stylesBillingReportPDF.textStyle}>
                                        Peso bruto: {`${receipt?.grossWeight ?? ""} kg`}
                                    </Text>
                                </View>






                            </>
                        </View>
                    )}
                    <View style={{ marginTop: 10, textAlign: "right" }}>
                        <Text>
                            Fecha: {moment(receipt?.createdAt).format("DD [de] MMMM [de] YYYY")}
                        </Text>
                        <Text>
                            {/* {order?.isPreReceipt
                ? `Pre-Factura No.${order?.preOperationNumber}/${parseISO(
                  order?.createdAt!
                ).getFullYear()}`
                : `Factura No.${order?.operationNumber ?? ""}/${parseISO(
                  order?.createdAt!
                ).getFullYear()}`} */}
                            Carga No {`${receipt?.operationNumber}/${parseISO(
                                receipt?.createdAt!
                            ).getFullYear()}`}
                        </Text>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Número de identificación: </Text> */}
                            <Text style={stylesBillingReportPDF.textStyle}>
                                Número de tracking: {receipt?.trackingNumber ?? "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Número de identificación: </Text> */}
                            <Text style={stylesBillingReportPDF.textStyle}>
                                Número de levante: {receipt?.levanteNumber ?? "-"}
                            </Text>
                        </View>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Número de identificación: </Text> */}
                            <Text style={stylesBillingReportPDF.textStyle}>
                                Origen: {receipt?.origin?.name ?? "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            {/* <Text>Número de identificación: </Text> */}
                            <Text style={stylesBillingReportPDF.textStyle}>
                                Destino: {receipt?.destiny?.name ?? "-"}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
            {/* Tabla con la informacion de los productos */}
            <View>
                <BillingTableTemplate
                    data={
                        receiptProducts?.map((prod) => {


                            return {
                                Producto: (
                                    <View
                                        style={
                                            (stylesBillingReportPDF.flex,
                                                stylesBillingReportPDF.flexCol)
                                        }
                                    >
                                        <Text style={[{ textAlign: "left" }]}>{prod.product.name} </Text>

                                        <Text style={[{ textAlign: "left" }]}>
                                            {prod.uniqueCode}
                                        </Text>
                                    </View>
                                ),
                                "Inicial": prod.entryQuantity,
                                "Disponible": prod.availableQuantity,
                                "Costo neto": (
                                    <Text style={[{ textAlign: "left" }]}>
                                        {formatCurrency(
                                            prod.netCost?.amount ?? 0,
                                            prod.netCost?.codeCurrency
                                        )}
                                    </Text>
                                ),
                                "Total neto": (
                                    <Text style={[{ textAlign: "left" }]}>
                                        {formatCurrency(
                                            (prod.netCost?.amount !== 0 && !!prod.netCost?.amount) ? (prod.netCost?.amount! * prod.entryQuantity!) : 0,
                                            prod.netCost?.codeCurrency
                                        )}
                                    </Text>
                                ),
                                "Costo bruto": (
                                    <Text style={[{ textAlign: "left" }]}>
                                        {formatCurrency(
                                            prod.grossCost?.amount ?? 0,
                                            prod.grossCost?.codeCurrency
                                        )}
                                    </Text>
                                ),


                                "Total bruto": (
                                    <Text style={[{ textAlign: "left" }]}>
                                        {formatCurrency(
                                            (prod.grossCost?.amount !== 0 && !!prod.grossCost?.amount) ? (prod.grossCost?.amount! * prod.entryQuantity!) : 0,
                                            prod.grossCost?.codeCurrency
                                        )}
                                    </Text>
                                ),
                            };

                        }) || []

                    }
                />
            </View>

            {/*  */}
            <View style={stylesBillingReportPDF.flexCol}>

                {receipt?.observations && (
                    <View style={{ width: "100%", paddingTop: 10 }}>
                        <div>
                            <View>
                                <Text
                                    style={{
                                        borderBottom: "1px solid black",
                                        paddingBottom: 3,
                                        marginBottom: 4,
                                        width: "30%",
                                    }}
                                >
                                    Observaciones:{" "}
                                </Text>
                                <Text
                                    style={{
                                        ...stylesBillingReportPDF.textStyle,
                                        flexWrap: "wrap",
                                        paddingRight: 4,
                                    }}
                                >
                                    {" "}
                                    {receipt?.observations}{" "}
                                </Text>
                            </View>
                        </div>
                    </View>
                )}
            </View>
            <Text style={stylesBillingReportPDF.textStyle}>
                {invoice_observations}
            </Text>

        </DocumentPage>
    );
};
export default CargoReportPDF;

const getCurrencyRate = (
    code: string,
    availableCurrencies: AvailableCurrency[]
) => {
    const currency = availableCurrencies.find((item) => item.code === code);
    return currency?.exchangeRate || 1;
};

const calculatePaymentDiff = (
    totalToPay: PriceInvoiceInterface[] = [],
    payments: PriceInvoiceInterface[] = [],
    availableCurrencies: AvailableCurrency[],
    mainCurrencyBusiness: string
) => {
    let paymentState: (PriceInvoiceInterface & { diff: number })[] =
        totalToPay.map((item) => ({ ...item, diff: item.amount }));
    let remain = 0;
    //match same currencies ------------------
    payments.forEach((payment) => {
        const idx = paymentState.findIndex(
            (toPay) => toPay.codeCurrency === payment.codeCurrency
        );
        if (idx !== -1) {
            const matchCurrency = paymentState[idx];
            //Fixed temp redondeo
            const diff = roundToTwoDecimalDow(payment.amount - matchCurrency.diff);
            if (diff > 0) {
                matchCurrency.diff = 0;
                const sum = roundToTwoDecimalDow(
                    mathOperation(
                        diff,
                        getCurrencyRate(payment.codeCurrency, availableCurrencies),
                        "multiplication",
                        2
                    )
                );
                remain = mathOperation(remain, sum, "addition");
            } else {
                matchCurrency.diff = Math.abs(diff);
            }
            paymentState.splice(idx, 1, matchCurrency);
        } else {
            const sum = mathOperation(
                payment.amount,
                getCurrencyRate(payment.codeCurrency, availableCurrencies),
                "multiplication"
            );
            remain = mathOperation(remain, sum, "addition");
            //remain += payment.amount * getCurrencyRate(payment.codeCurrency);
        }
    });
    //------------------------------------------------------------

    //complete with remain (first not main currencies) ------------------------
    while (remain > 0) {
        //find non zero & not null differences & not mainCurrency -------------------------
        const idx = paymentState.findIndex(
            (item) =>
                ![0, null].includes(item.diff) &&
                item.codeCurrency !== mainCurrencyBusiness
        );
        if (idx !== -1) {
            const notMainOrNull = paymentState[idx];
            const remainConverted =
                remain /
                getCurrencyRate(notMainOrNull.codeCurrency, availableCurrencies);
            const diff = remainConverted - notMainOrNull.diff!;
            if (diff > 0) {
                notMainOrNull.diff = 0;
                remain =
                    diff *
                    getCurrencyRate(notMainOrNull.codeCurrency, availableCurrencies);
                totalToPay.splice(idx, 1, notMainOrNull);
            } else {
                remain = 0;
                notMainOrNull.diff = Math.abs(diff);
                totalToPay.splice(idx, 1, notMainOrNull);
                break;
            }
        }

        //-------------------------------------------------------------------

        //find null difference & notMainCurrency ----------------------------------------------
        const diffIsNull = paymentState.find(
            (item) => item.diff === null && item.codeCurrency !== mainCurrencyBusiness
        );
        if (diffIsNull) {
            const remainConverted =
                remain / getCurrencyRate(diffIsNull.codeCurrency, availableCurrencies);
            const diff = remainConverted - diffIsNull.amount;
            if (diff > 0) {
                diffIsNull.diff = 0;
                remain =
                    diff * getCurrencyRate(diffIsNull.codeCurrency, availableCurrencies);
            } else {
                remain = 0;
                diffIsNull.diff = Math.abs(diff);
                break;
            }
        }

        //-------------------------------------------------------------------------------------

        //find mainCurrency ------------------------------------------------------------------
        const mainCurrency = paymentState.find(
            (item) => item.codeCurrency === mainCurrencyBusiness && item.diff !== 0
        );
        if (mainCurrency) {
            const remainConverted =
                remain /
                getCurrencyRate(mainCurrency.codeCurrency, availableCurrencies);
            const diff = remainConverted - (mainCurrency.diff ?? mainCurrency.amount);
            if (diff > 0) {
                mainCurrency.diff = 0;
                remain =
                    diff *
                    getCurrencyRate(mainCurrency.codeCurrency, availableCurrencies);
            } else {
                remain = 0;
                mainCurrency.diff = Math.abs(diff);
                break;
            }
        } else {
            break;
        }
        //-----------------------------------------------------------------------------------
    }
    //--------------------------------------------------------------------------
    //Complete null diff with amount---------------------------------------------
    paymentState = paymentState.map((item) =>
        item.diff === null ? { ...item, diff: item.amount } : item
    );
    //---------------------------------------------------------------------------
    return paymentState
        .filter((item) => item.diff !== 0)
        .map((itm) => ({
            amount: roundToTwoDecimal(itm.diff!),
            codeCurrency: itm.codeCurrency,
        }));
};
