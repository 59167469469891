import {
  BusinessInterface,
  DispatchItemInterface,
} from '../interfaces/ServerInterfaces';
import { translateDispatchStatus, translateMeasure } from '../utils/translate';
import Report from './components/Report';
import moment from 'moment';

interface DispatchReportProps {
  reportName: string;
  businessData: BusinessInterface;
  reportData: DispatchItemInterface;
}

const DispatchReport = ({
  reportName,
  businessData,
  reportData,
}: DispatchReportProps) => {

  const dispatchWord = reportData.isPreDispatch ? "predespacho" : 'despacho'

  const dispatchInfo: any = {
    [`Estado de ${dispatchWord}:`]: translateDispatchStatus(reportData.status),
    [`Fecha de ${dispatchWord}:`]: moment(reportData.createdAt).format(
      'DD [de] MMMM [de] YYYY'
    ),
    'Número de pedido:': reportData.id.toString(),
  };

  // Solo agregar la fecha de entrega si el estado no es "CREATED"
  if (reportData.status !== "CREATED" && reportData.status !== "REJECTED") {
    dispatchInfo[reportData.isPreDispatch ? "Fecha de aceptación:" : 'Fecha de entrega:'] = moment(reportData.receivedAt).format(
      'DD [de] MMMM [de] YYYY'
    );
  }

  const data = [
    `${reportName}~title`,
    'titleSeparator',
    {
      display: 'section',
      widths: [50, [30, 40]],
      subsections: [
        [
          `${reportData.isPreDispatch ? "Solicitado por" : "Origen"}:~subtitle`,
          reportData?.stockAreaFrom?.name ?? '-',
          reportData.createdBy?.displayName || '-',
          reportData.createdBy?.email || '-',
        ],
        dispatchInfo,
      ],
    },
    'titleSeparator',
    {
      display: 'section',
      widths: [50],
      subsections: [
        [
          `${reportData.isPreDispatch ? "Solicitado a" : "Destino"}:~subtitle`,
          reportData.stockAreaTo.name,
          reportData.receivedBy?.displayName || '',
          reportData.receivedBy?.email || '',
        ],
      ],
    },
    'sectionSeparator',
    {
      display: 'table',
      headers: ['Producto', 'Cantidad~nColumn', "Agrupación"],
      values: reportData.products.map((prod) => {

        const quantityByGroup: (quantity: number) => string | void = (quantity) => {
          if (prod.product.enableGroup) {
            const rest = quantity % (prod.product.groupConvertion ?? 1);
            return `${Math.trunc(quantity / (prod.product.groupConvertion ?? 1))} ${prod.product.groupName ?? ""} ${rest !== 0 ? "(+" + rest + translateMeasure(prod.measure ?? "") + ")" : ""
              }`;
          } else return "-"
        };

        return [
          prod.name,
          // eslint-disable-next-line no-useless-concat
          `${prod.quantity} ${translateMeasure(prod?.measure)}` + '~nColumn',
          quantityByGroup(prod.quantity) ?? "-"
        ]
      }
      ),
    },
  ];

  return (
    <Report
      reportName={reportName}
      reportData={data}
      headerData={businessData}
    />
  );
};

export default DispatchReport;
