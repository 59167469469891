/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useServerArea from "../../../../api/useServerArea";
import SpinnerLoading from "../../../../components/misc/SpinnerLoading";
import moment from "moment";
import { translateMeasure } from "../../../../utils/translate";
import MovementsTypeBadge from "../../../../components/misc/badges/MovementsTypeBadge";
import GenericList from "../../../../components/misc/GenericList";
import GenericTable, {
  DataTableInterface,
} from "../../../../components/misc/GenericTable";
import { TrashIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/modals/GenericModal";
import MovementDeleteComponent from "./MovementDeleteComponent";
import { formatCurrency } from "../../../../utils/helpers";
import { useAppSelector } from "../../../../store/hooks";
import StockCategoryTypeBadge from "../../../../components/misc/badges/StockCategoryTypeBadge";
import Button from "../../../../components/misc/Button";
import DetailDispatch from "../dispatch/DetailDispatch";
import { DispatchStatus } from "../../../../interfaces/ServerInterfaces";
import useServer from "../../../../api/useServerMain";
import { printPriceWithCommasAndPeriods } from "../../../../utils/functions";

interface MovementDetail {
  id: number | null;
}

const DetailMovement = ({ id }: MovementDetail) => {

  const { denyRoles } = useServer();

  const { getMovement, deleteStockMovement, movement, isLoading, isFetching, responseDispatch, transformDispatchToBill } =
    useServerArea();
  useEffect(() => {
    id && getMovement(id);
  }, []);

  const { business } = useAppSelector(state => state.init)

  const [deleteModal, setDeleteModal] = useState(false);

  const [showDispatchModal, setShowDispatchModal] = useState<{
    state: boolean;
    id: number | null;
  }>({ state: false, id: null });
  //Data to Display in list --------------------------------------------------------
  const header = {
    title: movement?.product?.name ?? "",
    subtitle: (
      <div className="flex flex-col gap-1 font-semibold">
        {movement?.variation && (
          <p className="text-gray-500">{movement?.variation?.name}</p>
        )}
        <div className="flex justify-start items-center gap-x-4 mt-1">
          <MovementsTypeBadge operation={movement?.operation ?? null} />
          <StockCategoryTypeBadge category={movement?.category} />
        </div>
      </div>
    ),
    alert: movement?.removedOperation !== null,
  };

  let displayData: Record<string, string | number | React.ReactNode> = {};
  displayData = {
    Fecha: moment(movement?.createdAt).format("DD/MM/YYYY hh:mm A"),
    Cantidad: `${movement?.quantity} ${translateMeasure(
      movement?.product?.measure
    )}`,
    "Realizado por": movement?.movedBy?.displayName,
    Área: movement?.area.name,
    Observaciones: movement?.description,
  };

  if (
    movement?.operation === "ENTRY" &&
    !movement?.parentId &&
    movement?.price
  ) {
    displayData["Precio unitario de compra"] = formatCurrency(
      movement?.price.amount / movement.quantity,
      movement?.price?.codeCurrency
    );
    displayData["Precio total de compra"] = formatCurrency(
      movement?.price.amount,
      movement?.price?.codeCurrency
    );
  }
  if (movement?.operation === "MOVEMENT") {
    displayData["Movido a"] = movement?.movedTo?.name;
  }
  if (movement?.removedOperation) {
    displayData["Detalles de elimininación"] = (
      <GenericList
        body={{
          Por: movement?.removedOperation.movedBy.displayName,
          Fecha: moment(movement?.removedOperation.createdAt).format("LLL"),
        }}
      />
    );
  }
  if (movement?.category === "PROCESSED") {
    displayData["Costo antes de la producción"] = formatCurrency(
      movement?.costBeforeOperation,
      business?.costCurrency
    )
    displayData["Costo después de la producción"] = !!movement?.costAfterOperation ? formatCurrency(
      movement?.costAfterOperation,
      business?.costCurrency
    ) : "-"
  }
  if (movement?.parent || movement?.childs.length !== 0) {

    const titles = ["Tipo", "Producto", "Área", "Cantidad", "Realizado por"];

    if (movement?.category === "DESCOMPOSITION" || movement?.category === "ELABORATION") {
      titles.splice(3, 0, "Costo antes")
      titles.splice(4, 0, "Costo después")
    }

    const tableData: DataTableInterface[] = [];
    movement?.parent &&
      tableData.push({
        payload: {
          Tipo: <MovementsTypeBadge operation={movement?.parent.operation} />,
          Producto: movement?.parent?.product?.name ?? "",
          Área: movement?.parent?.area.name,
          Cantidad: `${movement?.parent.quantity} ${translateMeasure(
            movement?.parent?.product?.measure
          )}`,
          "Costo antes": printPriceWithCommasAndPeriods(movement?.costBeforeOperation) + " " + business?.costCurrency,
          "Costo después": printPriceWithCommasAndPeriods(movement?.costAfterOperation) + " " + business?.costCurrency,
          "Realizado por": movement?.parent.movedBy.displayName,
        },
      });
    movement?.childs.length !== 0 &&
      movement?.childs.map((item) => {
        tableData.push({
          payload: {
            Tipo: <MovementsTypeBadge operation={item?.operation} />,
            Producto: item?.product?.name ?? "",
            Área: item?.area?.name ?? "No",
            Cantidad: `${item?.quantity ?? ""} ${translateMeasure(
              item?.product?.measure ?? ""
            )}`,
            "Realizado por": item?.movedBy?.displayName,
          },
        });
      });
    displayData["Operaciones Asociadas"] = (
      <GenericTable tableTitles={titles} tableData={tableData} />
    );
  }



  const modalResponseDispatch = (
    id: number,
    response: DispatchStatus,
    isPreDispatch: boolean,
    payload?: Record<string, any>,
    closeModal?: Function,
  ) => {
    if (["ACCEPTED", "REJECTED"].includes(response)) {
      responseDispatch(id, response, () =>
        setShowDispatchModal({ id: null, state: false }),
        isPreDispatch
      );
    } else if (response === "BILLED") {
      transformDispatchToBill(id, payload!, () => {
        setShowDispatchModal({ id: null, state: false })
        closeModal!()
      }
      );
    }
  };


  //-----------------------------------------------------------------------------------------
  if (isLoading)
    return (
      <div className="h-96">
        <SpinnerLoading />
      </div>
    );
  return (
    <>
      <GenericList
        header={header}
        body={displayData}
      />

      <div className="flex justify-end items-center gap-2 mt-4">

        {
          !!movement?.dispatch?.id && (
            <Button
              name="Ver despacho asociado"
              textColor="slate-600"
              color="slate-600"
              action={() => setShowDispatchModal({ state: true, id: movement?.dispatch?.id })}
              outline
              type="button"
            />
          )
        }
        {
          denyRoles(["AUDITOR"]) && <Button
            name="Eliminar"
            textColor="red-600"
            color="red-600"
            action={() => setDeleteModal(true)}
            outline
            type="button"
            icon={<TrashIcon className="h-5 text-red-600" />}
          />
        }

      </div>

      {deleteModal && (
        <Modal close={() => setDeleteModal(false)} state={deleteModal}>
          <MovementDeleteComponent
            deleteAction={(value: { description: string }) =>
              deleteStockMovement(id ?? 0, value, () => {
                getMovement(id ?? 0);
                setDeleteModal(false);
              })
            }
            name={movement?.product.name ?? ""}
            movType={movement?.operation}
            loading={isFetching}
          />
        </Modal>
      )}

      {showDispatchModal && (
        <Modal
          close={() => setShowDispatchModal({ state: false, id: null })}
          state={showDispatchModal.state}
          size="l"
        >
          <DetailDispatch
            id={showDispatchModal.id}
            response={modalResponseDispatch}
            loading={isFetching}
          />
        </Modal>
      )}
    </>
  );
};

export default DetailMovement;
