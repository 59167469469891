import bigDecimal from "js-big-decimal";
import DocumentPage from "../components/pdf/DocumentPage";
import TableTemplate from "../components/pdf/TableTemplate";
import {
  AvailableCurrency,
  BusinessInterface,
  GenericPriceInterface,
  OrderInterface,
  PriceInvoiceInterface,
  SimplePrice,
} from "../interfaces/ServerInterfaces";

import { View, Text, Image as Logo } from "@react-pdf/renderer";
import {
  getMeasureSpanish,
  roundToTwoDecimal,
  roundToTwoDecimalDow,
} from "../utils/functions";
import moment from "moment";
import { parseISO } from "date-fns";
import APIMediaServer from "../api/APIMediaServer";
import { blobToDataURL } from "./helpers/commons";
import {
  translateOrderState,
  translatePaymetMethods,
  translatePaymetMethodsReduce,
  translatePaymetMethodsShort,
} from "../utils/translate";
import { mathOperation } from "../utils/helpers";
import BillingTableTemplate from "../components/pdf/BillingTableTemplate";
import { stylesBillingReportPDF } from "../utils/stylesHelpers";

function printPriceWithCommasAndPeriods(price: number) {
  const roundedPrice = Number(price).toFixed(2);
  const formattedPrice = Number.isInteger(price)
    ? Number(price).toFixed(2)
    : roundedPrice;
  return formattedPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

interface BillingReportPdfInterface {
  order: OrderInterface | null | undefined;
  business: BusinessInterface | null;
  reportType?: "billing" | "delivery" | "prebilling" | "delivery_valued";
  exportAtExchangeRate?: {
    codeCurrency: string;
    exchangeRate: number;
  };
}

type ConvertCurrencyWithRateFunction = (
  price: GenericPriceInterface,
  codeCurrency: string,
  rate: number
) => GenericPriceInterface;

const BillingReportPdf = ({
  order,
  business,
  reportType,
  exportAtExchangeRate,
}: BillingReportPdfInterface) => {
  const { availableCurrencies, mainCurrency } = business!;


  const precission = Number(
    business?.configurationsKey.find(
      (item) => item.key === "precission_after_coma"
    )?.value
  );

  const billing_print_force_currency =
    business!.configurationsKey.find(
      (configuration: { key: string }) =>
        configuration.key === "billing_print_force_currency"
    )?.value === "true";

  const billing_print_force_currency_defined = business!.configurationsKey.find(
    (configuration: { key: string }) =>
      configuration.key === "billing_print_force_currency_defined"
  )?.value!;

  const billing_print_force_currency_defined_exchangeRate =
    availableCurrencies.find(
      (curr) => curr.code === billing_print_force_currency_defined
    )?.exchangeRate;

  const convertCurrencyWithRate: ConvertCurrencyWithRateFunction = (
    price,
    codeCurrency,
    rate
  ) => {

    const exchangeRate = rate;

    if (price.codeCurrency === codeCurrency) return price;

    let targetCurrency = availableCurrencies.find(
      (curr) => curr.code === codeCurrency
    );

    const key: keyof GenericPriceInterface =
      price.price !== undefined ? "price" : "amount";

    // Verificar que la moneda especificada existe y está configurada en el negocio
    if (!targetCurrency) {
      return price;
    }

    let priceInMainCurrency: GenericPriceInterface = price;

    // Obtener precio en la moneda principal
    if (price.codeCurrency !== mainCurrency) {
      const priceAmount = new bigDecimal(price.price || price.amount);

      const currency = availableCurrencies.find(
        (curr) => curr.code === price.codeCurrency
      )!;
      const rate = new bigDecimal(
        codeCurrency === "CUP" ? exchangeRate : currency?.exchangeRate
      );

      // Multiplicar y redondear con precisión
      const amount = priceAmount.multiply(rate).getValue();
      priceInMainCurrency[key] = Number(amount);
      priceInMainCurrency.codeCurrency = mainCurrency;
    }

    // Si la moneda de destino es la moneda principal
    if (codeCurrency === mainCurrency) {
      return {
        [key]: priceInMainCurrency[key],
        codeCurrency: priceInMainCurrency.codeCurrency,
      };
    }


    // Dividir y calcular el precio final
    const targetPrice = new bigDecimal(priceInMainCurrency[key])
      .divide(new bigDecimal(exchangeRate))
      // .round(5) // Aumentamos la precisión a 5 decimales para mantener exactitud
      .getValue();

    // Convertir a 2 decimales para mostrar
    // const formattedPrice = Number(targetPrice);
    const formattedPrice = Number(targetPrice).toFixed(2);

    return { [key]: Number(formattedPrice), codeCurrency };

  };


  const invoice_business_name = business?.configurationsKey.find(
    (config) => config.key === "invoice_business_name"
  )?.value;

  const invoice_header = business?.configurationsKey.find(
    (config) => config.key === "invoice_header"
  )?.value;

  const invoice_observations = business?.configurationsKey.find(
    (config) => config.key === "invoice_observations"
  )?.value;

  const billing_show_customer_data =
    business?.configurationsKey.find(
      (config) => config.key === "billing_show_customer_data"
    )?.value === "true";
  const billing_show_business_data =
    business?.configurationsKey.find(
      (config) => config.key === "billing_show_business_data"
    )?.value === "true";
  const billing_show_business_logo =
    business?.configurationsKey.find(
      (config) => config.key === "billing_show_business_logo"
    )?.value === "true";

  const getBusinessLogo = async (logoId: number) => {
    try {
      const response = await APIMediaServer.get("/files/" + logoId, {
        responseType: "blob",
      });
      return await blobToDataURL(response.data);
    } catch (error) {
      // console.error(er ror);
      return require("../assets/image-default.jpg");
    }
  };

  const titlePDF = () => {
    if (reportType === "billing") {
      return "Factura";
    }
    if (reportType === "delivery") {
      return "Albaran";
    }
    if (reportType === "delivery_valued") {
      return "Albaran-Valorado";
    }
    if (reportType === "prebilling") {
      return "Pre-Factura";
    }
  };

  let subtotal: Array<{ amount: number; codeCurrency: string }> = [];

  order?.selledProducts.forEach((prod) => {
    if (
      subtotal.find(
        (elem) =>
          elem.codeCurrency ===
          (prod.priceUnitary !== null
            ? prod.priceUnitary?.codeCurrency
            : prod.priceTotal?.codeCurrency)
      )
    ) {
      subtotal = subtotal.map((item) => {
        if (
          item.codeCurrency ===
          (prod.priceUnitary !== null
            ? prod.priceUnitary?.codeCurrency
            : prod.priceTotal?.codeCurrency)
        ) {
          return {
            codeCurrency: item.codeCurrency,
            amount:
              (prod.priceTotal?.amount ||
                prod.priceUnitary?.amount * prod.quantity) + item.amount,
          };
        } else {
          return item;
        }
      });
    } else {
      subtotal.push({
        amount:
          prod.priceTotal?.amount || prod.priceUnitary?.amount * prod.quantity,
        codeCurrency:
          prod.priceUnitary !== null
            ? prod.priceUnitary?.codeCurrency
            : prod.priceTotal?.codeCurrency,
      });
    }
  });

  const discount: PriceInvoiceInterface[] = [];
  if (order?.couponDiscountPrice || order?.discount !== 0) {
    if (order?.couponDiscountPrice) discount.push(order?.couponDiscountPrice);
    if (order?.discount !== 0) {
      order?.prices.forEach((item) => {
        const amount = (order.discount / 100) * item.price;
        const idx = discount.findIndex(
          (elem) => elem.codeCurrency === item.codeCurrency
        );
        if (idx !== -1) {
          discount.splice(idx, 1, {
            ...discount[idx],
            amount: discount[idx].amount + amount,
          });
        } else {
          discount.push({ amount: amount, codeCurrency: item.codeCurrency });
        }
      });
    }
  }

  const comission: PriceInvoiceInterface[] = [];
  if (order?.commission! > 0) {
    order?.prices.forEach((item) => {
      const amount = (order.commission / 100) * item.price;
      const idx = comission.findIndex(
        (elem) => elem.codeCurrency === item.codeCurrency
      );
      if (idx !== -1) {
        comission.splice(idx, 1, {
          ...comission[idx],
          amount: comission[idx]?.amount + amount,
        });
      } else {
        comission.push({ amount: amount, codeCurrency: item.codeCurrency });
      }
    });
  }

  function sumarPriceInvoices(
    ...priceInvoices: (PriceInvoiceInterface | PriceInvoiceInterface[])[]
  ): PriceInvoiceInterface[] {
    const combined: { [key: string]: number } = {};

    // Sumar los valores de los objetos individuales
    for (const invoice of priceInvoices) {
      if (invoice !== null && invoice !== undefined) {
        if (Array.isArray(invoice)) {
          for (const item of invoice) {
            if (item && item.codeCurrency) {
              if (invoice === discount) {
                combined[item.codeCurrency] =
                  (combined[item.codeCurrency] || 0) - item.amount;
              } else {
                combined[item.codeCurrency] =
                  (combined[item.codeCurrency] || 0) + item.amount;
              }
            }
          }
        } else {
          if (invoice.codeCurrency) {
            combined[invoice.codeCurrency] =
              (combined[invoice.codeCurrency] || 0) + invoice.amount;
          }
        }
      }
    }

    // Convertir el objeto combinado en un arreglo de PriceInvoiceInterface
    const result: PriceInvoiceInterface[] = [];
    for (const codeCurrency in combined) {
      if (combined.hasOwnProperty(codeCurrency)) {
        result.push({ amount: combined[codeCurrency], codeCurrency });
      }
    }

    return result;
  }

  const totalPartialPay: SimplePrice[] = [];

  if (order?.status !== "BILLED") {
    if (order?.partialPayments && order?.partialPayments?.length > 0) {
      for (const item of order?.partialPayments) {
        const found = totalPartialPay.find(
          (data) => data.codeCurrency === item.codeCurrency
        );

        if (found) {
          found.amount += item.amount;
        } else {
          totalPartialPay.push({
            amount: item.amount,
            codeCurrency: item.codeCurrency,
          });
        }
      }
    }
  }

  const difference = calculatePaymentDiff(
    order?.totalToPay,
    [...(order?.partialPayments || [])],
    business?.availableCurrencies as AvailableCurrency[],
    business?.mainCurrency as string
  );

  const statusNotPay = ["CREATED", "PAYMENT_PENDING", "OVERDUE"];
  const totalToPay: any = statusNotPay.includes(order?.status || "")
    ? [...difference]
    : [...(order?.totalToPay || [])];


  function printBillingReportPDFPrice(
    item: { amount: number; codeCurrency: string },
    modifier?: number
  ) {

    const itemAmount = new bigDecimal(item.amount);
    const modifierAmount = new bigDecimal(!!modifier ? modifier : 1);

    const price = itemAmount.multiply(modifierAmount).getValue();

    return printPriceWithCommasAndPeriods(
      billing_print_force_currency
        ? convertCurrencyWithRate(
          {
            amount: item.amount,
            codeCurrency: item.codeCurrency,
          },
          billing_print_force_currency_defined!,
          billing_print_force_currency_defined_exchangeRate!
        ).amount! * (!!modifier ? modifier : 1)
        : !!exportAtExchangeRate
          ? convertCurrencyWithRate(
            {
              amount: item.amount,
              codeCurrency: item.codeCurrency,
            },
            exportAtExchangeRate.codeCurrency!,
            exportAtExchangeRate.exchangeRate!
          ).amount!
          : Number(price)
    );
  }

  function printBillingReportPDFCodecurrency(codeCurrency: string) {
    return billing_print_force_currency
      ? billing_print_force_currency_defined
      : !!exportAtExchangeRate
        ? // @ts-ignore
        exportAtExchangeRate?.codeCurrency!
        : codeCurrency;
  }

  const shippingPrice =
    printBillingReportPDFPrice({
      amount: order?.shippingPrice?.amount!,
      codeCurrency: order?.shippingPrice?.codeCurrency!,
    }) ?? "-";

  const shippingCodeCurrency = printBillingReportPDFCodecurrency(
    order?.shippingPrice?.codeCurrency!
  );

  const amountReturnedPrice =
    printBillingReportPDFPrice({
      amount: order?.amountReturned?.amount!,
      codeCurrency: order?.amountReturned?.codeCurrency!,
    }) ?? "-";

  const amountReturnedCodeCurrency = printBillingReportPDFCodecurrency(
    order?.amountReturned?.codeCurrency!
  );


  const billing_show_total_payment_gateway = business!.configurationsKey.find(
    (configuration: { key: string; }) => configuration.key === "billing_show_total_payment_gateway"
  )?.value;

  return (
    <DocumentPage>
      <View style={stylesBillingReportPDF.h1}>
        <Text
          style={{
            ...stylesBillingReportPDF.h1,
            ...stylesBillingReportPDF.bottomBorder,
          }}
        >
          {titlePDF()}
        </Text>
      </View>
      {["CANCELLED", "REFUNDED"].includes(order?.status ?? "") && (
        <View style={stylesBillingReportPDF.statusPage}>
          <Text style={{ position: "relative", zIndex: 1000 }}>
            {translateOrderState(order?.status ?? "")}
          </Text>
        </View>
      )}
      {billing_show_business_logo && (
        <View style={stylesBillingReportPDF.pageStart}>
          <div>
            <View style={stylesBillingReportPDF.flexCol}>
              <Logo
                style={stylesBillingReportPDF.businessLogo}
                //@ts-ignore
                src={
                  business?.logo
                    ? getBusinessLogo(business?.logo?.id!)
                    // ? business.logo.src
                    : require("../assets/image-default.jpg")
                }
              />
            </View>
          </div>
        </View>
      )}
      <View
        style={{ ...stylesBillingReportPDF.flexCol, gap: 5, width: "100%" }}
      >
        {billing_show_business_data && (
          <View
            style={{
              ...stylesBillingReportPDF.flexCol,
              width: "50%",
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Text>Negocio: </Text> */}
              <Text style={stylesBillingReportPDF.bigTextStyle}>
                {invoice_business_name || business?.name}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Text>Negocio: </Text> */}
              <Text style={stylesBillingReportPDF.textStyle}>
                {invoice_header}
              </Text>
            </View>

            {business?.email && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <Text>Correo electrónico: </Text> */}
                <Text style={stylesBillingReportPDF.textStyle}>
                  {business?.email}
                </Text>
              </View>
            )}

            {business?.phones &&
              business?.phones.length > 0 &&
              Number(business?.phones[0]?.number) !== 0 && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <Text>Teléfono: </Text> */}
                  <Text style={stylesBillingReportPDF.textStyle}>
                    {business?.phones[0].number}
                  </Text>
                </View>
              )}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Text>Dirección: </Text> */}
              <Text
                style={{
                  ...stylesBillingReportPDF.textStyle,
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {(business?.address?.street_1
                  ? business?.address?.street_1 + ", "
                  : "") +
                  (business?.address?.street_2
                    ? business?.address?.street_2 + ", "
                    : "") +
                  (business?.address?.city
                    ? business?.address?.city + ", "
                    : "") +
                  (business?.address?.municipality?.name
                    ? business?.address?.municipality?.name + ", "
                    : "") +
                  (business?.address?.province?.name
                    ? business?.address?.province?.name
                    : "")}
              </Text>
            </View>
          </View>
        )}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {billing_show_customer_data && (
            <View style={{ ...stylesBillingReportPDF.flexCol, width: "50%" }}>
              {order?.client && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text>Cliente: </Text>
                  <Text style={stylesBillingReportPDF.textStyle}>
                    {(order?.client?.firstName || order?.client?.lastName) &&
                      (order?.client?.firstName ?? "") +
                      " " +
                      (order?.client?.lastName ?? "")}
                  </Text>
                </View>
              )}

              {order?.client?.address?.country && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text>País: {order?.client?.address?.country?.name} </Text>
                </View>
              )}
              {order?.client?.address?.province && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text>
                    Provincia: {order?.client?.address?.province?.name}{" "}
                  </Text>
                </View>
              )}
              {order?.client?.address?.municipality && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text>
                    Municipio: {order?.client?.address?.municipality?.name}
                  </Text>
                </View>
              )}
              {order?.client !== null && (
                <>
                  {/* {order?.client?.codeClient && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text>Código de cliente: </Text>
                      <Text style={stylesBillingReportPDF.textStyle}>
                        {order?.client.codeClient}
                      </Text>
                    </View>
                  )} */}
                  {order?.client?.ci && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* <Text>Número de identificación: </Text> */}
                      <Text style={stylesBillingReportPDF.textStyle}>
                        {order?.client.ci}
                      </Text>
                    </View>
                  )}
                  {order?.client?.phones &&
                    order?.client?.phones?.length > 0 &&
                    Number(order?.client?.phones[0]?.number) !== 0 && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {/* <Text>Teléfono: </Text> */}
                        <Text style={stylesBillingReportPDF.textStyle}>
                          {order?.client?.phones[0].number}
                        </Text>
                      </View>
                    )}
                  {order?.client?.email && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* <Text>Correo electrónico: </Text> */}
                      <Text style={stylesBillingReportPDF.textStyle}>
                        {order?.client?.email}
                      </Text>
                    </View>
                  )}
                  {order?.client?.contractNumber && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* <Text>No. de contrato: </Text> */}
                      <Text style={stylesBillingReportPDF.textStyle}>
                        {order?.client?.contractNumber}
                      </Text>
                    </View>
                  )}
                </>
              )}

              {order?.billing && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <Text>Dirección: </Text> */}
                  <Text
                    style={{
                      ...stylesBillingReportPDF.textStyle,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {(order?.billing?.city ? order?.billing?.city + ", " : "") +
                      (order?.billing?.municipality?.name
                        ? order?.billing?.municipality?.name + ", "
                        : "") +
                      (order?.billing?.province?.name
                        ? order?.billing?.province?.name
                        : "")}
                  </Text>
                </View>
              )}
              {order?.client?.legalNotes && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text>Apuntes legales: </Text>
                  <Text style={stylesBillingReportPDF.textStyle}>
                    {order?.client.legalNotes}
                  </Text>
                </View>
              )}
            </View>
          )}
          <View style={{ marginTop: 10, textAlign: "right" }}>
            <Text>
              Fecha: {moment(order?.createdAt).format("DD [de] MMMM [de] YYYY")}
            </Text>
            <Text>
              {/* {order?.isPreReceipt
                ? `Pre-Factura No.${order?.preOperationNumber}/${parseISO(
                  order?.createdAt!
                ).getFullYear()}`
                : `Factura No.${order?.operationNumber ?? ""}/${parseISO(
                  order?.createdAt!
                ).getFullYear()}`} */}
              {`${!!order?.name
                ? `${order.name}.${order?.isPreReceipt
                  ? order?.preOperationNumber
                  : order?.operationNumber
                }`
                : `${order?.isPreReceipt
                  ? `Pre-Factura No.${order?.preOperationNumber}`
                  : `Factura No.${order?.operationNumber}`
                }/${parseISO(order?.createdAt!).getFullYear()}`
                }`}
            </Text>
          </View>
        </View>
      </View>
      {/* Tabla con la informacion de los productos */}
      <View>
        <BillingTableTemplate
          data={
            order?.selledProducts?.map((prod) => {
              const productPrice = printBillingReportPDFPrice({
                amount: prod.priceUnitary.amount,
                codeCurrency: prod.priceUnitary.codeCurrency,
              });

              const productTotalPrice = printBillingReportPDFPrice({
                amount:
                  prod.priceTotal?.amount,
                codeCurrency: prod.priceTotal?.codeCurrency,
              });

              const productCurrency = printBillingReportPDFCodecurrency(
                prod.priceUnitary.codeCurrency!
              );

              const productTotalCurrency = printBillingReportPDFCodecurrency(
                prod.priceTotal?.codeCurrency!
              );

              //Si es factura o prefactura devuelve esto
              if (reportType === "billing" || reportType === "prebilling") {
                return {
                  Producto: (
                    <View
                      style={
                        (stylesBillingReportPDF.flex,
                          stylesBillingReportPDF.flexCol)
                      }
                    >
                      <Text style={[{ textAlign: "left" }]}>{prod.name} </Text>

                      {/* //Codigo de barras del producto */}
                      {
                        prod?.barCode && (
                          <Text
                            style={{
                              color: "gray",
                              opacity: 0.8,
                              textAlign: "left",
                            }}
                          >
                            {prod?.barCode}
                          </Text>
                        )
                      }

                      {prod.observations && (
                        <Text
                          style={{
                            color: "gray",
                            opacity: 0.8,
                            textAlign: "left",
                          }}
                        >
                          {prod.observations}
                        </Text>
                      )}
                    </View>
                  ),
                  " ": " ",
                  // "Código barra": prod?.barCode! ?? "-",
                  UM: prod.type === "COMBO"
                    ? "Combo"
                    : prod.measure ? getMeasureSpanish(prod.measure) : "-",
                  Cant: (
                    <Text style={[{ textAlign: "center" }]}>
                      {prod.quantity}
                    </Text>
                  ),
                  "Precio unitario": (
                    <Text style={[{ textAlign: "center" }]}>
                      {productPrice + " " + productCurrency}
                    </Text>
                  ),
                  "Importe total": (
                    <Text style={[{ textAlign: "center" }]}>
                      {productTotalPrice + " " + productTotalCurrency}
                    </Text>
                  ),
                };
              }
              //Si es albaran valorado devuelve esto
              else if (reportType === "delivery_valued") {
                return {
                  Producto: prod.name,
                  " ": " ",
                  UM: prod.type === "COMBO"
                    ? "Combo"
                    : prod.measure ? getMeasureSpanish(prod.measure) : "-",
                  Cantidad: prod.quantity,
                  "Precio unitario": (
                    <Text style={[{ textAlign: "center" }]}>
                      {productPrice + " " + productCurrency}
                    </Text>
                  ),
                  "Importe total": (
                    <Text style={[{ textAlign: "center" }]}>
                      {prod.priceTotal?.amount + " " + prod.priceTotal?.codeCurrency}
                    </Text>
                  ),
                };
              }
              //Esto es en caso de que se exportar Albaran
              else {
                return {
                  Producto: prod.name,
                  " ": " ",
                  UM: prod.type === "COMBO"
                    ? "Combo"
                    : prod.measure ? getMeasureSpanish(prod.measure) : "-",
                  Cantidad: prod.quantity,
                };
              }
            }) || []
          }
        />
      </View>
      {reportType !== "delivery" && (
        <View style={stylesBillingReportPDF.pageEnd}>
          <View style={{ ...stylesBillingReportPDF.h2, width: 200 }}>
            <Text style={stylesBillingReportPDF.pageEndRow}>
              <Text style={stylesBillingReportPDF.pageEndTitle}>
                {order?.discount! > 0 || order?.commission! > 0
                  ? "Importe "
                  : "Subtotal "}
              </Text>
              <Text>
                {order?.prices?.map((itm) => {

                  const subtotalPrice = printBillingReportPDFPrice({
                    amount: itm?.price,
                    codeCurrency: itm?.codeCurrency ?? "",
                  });

                  const subtotalTotalCurrency =
                    printBillingReportPDFCodecurrency(itm.codeCurrency ?? "");

                  return (
                    <Text>
                      {subtotalPrice}{" "}
                      {subtotalTotalCurrency}
                      {"\n"}
                    </Text>
                  )
                })}
              </Text>
            </Text>

            {order?.shippingPrice && (
              <Text style={stylesBillingReportPDF.pageEndRow}>
                <Text style={stylesBillingReportPDF.pageEndTitle}>Envío </Text>
                <Text>
                  {order?.shipping
                    ? order?.shippingPrice?.amount
                      ? shippingPrice + " " + shippingCodeCurrency
                      : "-"
                    : "0"}
                </Text>
              </Text>
            )}

            {(order?.discount! > 0 || order?.couponDiscountPrice) &&
              order?.status === "BILLED" && (
                <Text style={stylesBillingReportPDF.pageEndRow}>
                  <Text style={stylesBillingReportPDF.pageEndTitle}>
                    Descuento:{" "}
                  </Text>
                  <Text>
                    <Text> {order.discount}%</Text>
                    <Text> </Text>
                    {discount.map((elem) => {
                      const discountPrice = printBillingReportPDFPrice({
                        amount: elem.amount,
                        codeCurrency: elem.codeCurrency,
                      });

                      const discountCurrency =
                        printBillingReportPDFCodecurrency(elem.codeCurrency);

                      return (
                        <Text>
                          {discountPrice + " " + discountCurrency + " "}
                        </Text>
                      );
                    })}
                  </Text>
                </Text>
              )}

            {order?.status === "BILLED" && (
              <>
                {order?.commission! > 0 && (
                  <Text style={stylesBillingReportPDF.pageEndRow}>
                    <Text style={stylesBillingReportPDF.pageEndTitle}>
                      Comisiones:{" "}
                    </Text>
                    <Text> {order.commission}%</Text>
                    <Text> </Text>
                    <Text>
                      {comission.map((elem) => {
                        const comissionPrice = printBillingReportPDFPrice({
                          amount: elem.amount,
                          codeCurrency: elem.codeCurrency,
                        });

                        const comissionCurrency =
                          printBillingReportPDFCodecurrency(elem.codeCurrency);

                        return (
                          <Text>
                            {comissionPrice + " " + comissionCurrency + " "}
                          </Text>
                        );
                      })}
                    </Text>
                  </Text>
                )}
              </>
            )}

            {(order?.couponDiscountPrice ||
              order?.discount !== 0 ||
              order?.commission! > 0 ||
              order?.shippingPrice ||
              order?.taxes) &&
              order?.status === "BILLED" && (
                <Text style={stylesBillingReportPDF.pageEndRow}>
                  <Text style={stylesBillingReportPDF.pageEndTitle}>
                    Subtotal{" "}
                  </Text>
                  <Text style={stylesBillingReportPDF.flexCol}>
                    {order?.prices?.map((itm) => (
                      <Text>
                        {printPriceWithCommasAndPeriods(
                          sumarPriceInvoices(
                            discount,
                            comission,
                            order?.taxes!,
                            order?.shippingPrice!
                          ).find(
                            (elem) => elem.codeCurrency === itm.codeCurrency
                          )
                            ? itm.price +
                            sumarPriceInvoices(
                              discount,
                              comission,
                              order?.taxes!,
                              order?.shippingPrice!
                            )?.find(
                              (elem) =>
                                elem.codeCurrency === itm.codeCurrency
                            )?.amount!
                            : itm.price
                        )}{" "}
                        {itm?.codeCurrency ?? ""}
                        {"\n"}
                      </Text>
                    ))}
                  </Text>
                </Text>
              )}

            {order?.orderModifiers?.length! > 0 && (
              <View style={stylesBillingReportPDF.pageEndRowWithoutFlex}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  {order?.orderModifiers?.map((modifier) => {
                    const modifierPrice = printBillingReportPDFPrice({
                      amount: modifier.amount,
                      codeCurrency: modifier.codeCurrency,
                    });
                    const modifierCurrrency = printBillingReportPDFCodecurrency(
                      modifier.codeCurrency
                    );

                    return (
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          textAlign: "right",
                        }}
                      >
                        <Text style={{ marginRight: 4 }}>
                          {modifier.showName}
                        </Text>
                        <Text>{modifierPrice}</Text>
                        <Text style={{ marginLeft: 4 }}>
                          {modifierCurrrency}{" "}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            )}

            <View style={stylesBillingReportPDF.pageEnd}>
              <View style={{ ...stylesBillingReportPDF.h2, width: 200 }}>
                <Text style={stylesBillingReportPDF.pageEndRow}>
                  {order?.status === "BILLED" ? (
                    <>
                      <Text style={stylesBillingReportPDF.pageEndTitle}>
                        Total pagado:{" "}
                      </Text>
                    </>
                  ) : (
                    <Text style={stylesBillingReportPDF.pageEndTitle}>
                      Total a pagar: {"  "}
                    </Text>
                  )}

                  {order?.status === "BILLED" ? (
                    <View>
                      {order?.currenciesPayment.map((item) => {
                        const currenciesPaymentPrice =
                          printBillingReportPDFPrice({
                            amount: item?.amount,
                            codeCurrency: item?.codeCurrency ?? "",
                          });

                        const currenciesPaymentTotalCurrency =
                          printBillingReportPDFCodecurrency(item.codeCurrency ?? "");

                        const currenciesPaymentGateway = billing_show_total_payment_gateway === "true"
                          ? translatePaymetMethodsReduce(item.paymentWay)
                          : "";


                        return (
                          <Text style={stylesBillingReportPDF.pageEndTitle}>
                            {!exportAtExchangeRate &&
                              translatePaymetMethodsShort(item.paymentWay)}{" "}
                            {currenciesPaymentPrice +
                              "  " +
                              currenciesPaymentTotalCurrency + " " + currenciesPaymentGateway}{" "}
                            {"\n"}
                          </Text>
                        );
                      })}
                    </View>
                  ) : (
                    <Text style={stylesBillingReportPDF.flexCol}>
                      {order?.totalToPay.map((item: SimplePrice) => {
                        const totalToPayPrice = printBillingReportPDFPrice({
                          amount: item?.amount,
                          codeCurrency: item?.codeCurrency ?? "",
                        });

                        const totalToPayTotalCurrency =
                          printBillingReportPDFCodecurrency(item.codeCurrency ?? "");

                        return (
                          <Text>
                            {totalToPayPrice +
                              "  " +
                              totalToPayTotalCurrency +
                              " " +
                              "\n"}
                          </Text>
                        );
                      })}
                    </Text>
                  )}
                </Text>
              </View>
            </View>

            {order?.amountReturned && order?.amountReturned?.amount! > 0 && (
              <Text style={stylesBillingReportPDF.pageEndFinalRow}>
                <Text style={stylesBillingReportPDF.pageEndTitle}>Cambio </Text>

                <Text style={stylesBillingReportPDF.flexCol}>
                  <Text>
                    {amountReturnedPrice +
                      "  " +
                      amountReturnedCodeCurrency +
                      " "}
                  </Text>
                </Text>
              </Text>
            )}

            {order?.paidAt && (
              <Text style={{ marginTop: 4, textAlign: "right" }}>
                {" "}
                Fecha de pago:{" "}
                {moment(order?.paidAt).format("DD [de] MMMM [de] YYYY")}{" "}
              </Text>
            )}
          </View>
        </View>
      )}
      {/*  */}
      <View style={stylesBillingReportPDF.flexCol}>
        {order?.shipping && reportType === "delivery" && (
          <View style={{ width: "100%", paddingTop: 10 }}>
            <div>
              <View>
                {order?.shipping ? (
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      paddingBottom: 3,
                      marginBottom: 4,
                      width: "30%",
                    }}
                  >
                    Datos de envío:{" "}
                  </Text>
                ) : (
                  ""
                )}

                {order?.shipping?.firstName ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Receptor:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.firstName +
                        " " +
                        order?.shipping?.lastName}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.phone &&
                  Number(order?.shipping?.phone) !== 0 ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    {/* <Text>Teléfono:</Text> */}
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.phone}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.email ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Correo electrónico:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.email}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.street_1 ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Calle principal:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.street_1}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.street_2 ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Calle secundaria:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.street_2}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.city ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Localidad:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.city}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.postalCode &&
                  Number(order?.shipping?.postalCode) !== 0 ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Código postal:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.postalCode}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.municipality ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Municipio:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.municipality?.name}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.province ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>Provincia:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.province?.name}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}

                {order?.shipping?.country ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text>País:</Text>
                    <Text style={stylesBillingReportPDF.textStyle}>
                      {" "}
                      {order?.shipping?.country?.name}{" "}
                    </Text>
                  </View>
                ) : (
                  ""
                )}
              </View>
            </div>
          </View>
        )}

        {order?.shipping?.description && reportType === "delivery" && (
          <View style={{ width: "100%", paddingTop: 10 }}>
            <div>
              <View>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    paddingBottom: 3,
                    marginBottom: 4,
                    width: "30%",
                  }}
                >
                  Descripción de envío:{" "}
                </Text>
                <Text
                  style={{
                    ...stylesBillingReportPDF.textStyle,
                    flexWrap: "wrap",
                    paddingRight: 4,
                  }}
                >
                  {" "}
                  {order?.shipping?.description}{" "}
                </Text>
              </View>
            </div>
          </View>
        )}

        {order?.partialPayments?.length! > 0 && reportType !== "delivery" && (
          <View style={{ width: "70%" }}>
            <Text
              style={{
                borderBottom: "1px solid black",
                paddingBottom: 3,
                marginBottom: 4,
                width: "30%",
              }}
            >
              {order?.status !== "BILLED"
                ? `Pagos parciales:`
                : `Detalles del pago`}{" "}
            </Text>
            <TableTemplate
              data={
                order?.partialPayments?.map((partial) => ({
                  Fecha: moment(partial.createdAt).format(
                    "DD [de] MMMM [de] YYYY"
                  ),
                  Monto:
                    printPriceWithCommasAndPeriods(partial.amount) +
                    " " +
                    partial.codeCurrency,
                  "Forma de pago": translatePaymetMethods(partial.paymentWay),
                  Observaciones: partial.observations,
                })) || []
              }
            />
          </View>
        )}

        {order?.customerNote && (
          <View style={{ width: "100%", paddingTop: 10 }}>
            <div>
              <View>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    paddingBottom: 3,
                    marginBottom: 4,
                    width: "30%",
                  }}
                >
                  Notas del cliente:{" "}
                </Text>
                <Text
                  style={{
                    ...stylesBillingReportPDF.textStyle,
                    flexWrap: "wrap",
                    paddingRight: 4,
                  }}
                >
                  {" "}
                  {order?.customerNote}{" "}
                </Text>
              </View>
            </div>
          </View>
        )}

        {order?.observations && (
          <View style={{ width: "100%", paddingTop: 10 }}>
            <div>
              <View>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    paddingBottom: 3,
                    marginBottom: 4,
                    width: "30%",
                  }}
                >
                  Observaciones:{" "}
                </Text>
                <Text
                  style={{
                    ...stylesBillingReportPDF.textStyle,
                    flexWrap: "wrap",
                    paddingRight: 4,
                  }}
                >
                  {" "}
                  {order?.observations}{" "}
                </Text>
              </View>
            </div>
          </View>
        )}
      </View>
      <Text style={stylesBillingReportPDF.textStyle}>
        {invoice_observations}
      </Text>
      (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ ...stylesBillingReportPDF.flex, marginTop: 50 }}>
          <View style={{ width: 100 }}>
            <Text style={{ borderBottom: "2px", width: 100 }}> </Text>
            <Text style={{ textAlign: "center", fontSize: 10 }}>
              Entregado{" "}
            </Text>
          </View>
          <View style={{ width: 100 }}>
            <Text style={{ borderBottom: "2px", width: 100 }}> </Text>
            <Text style={{ textAlign: "center", fontSize: 10 }}>Recibido </Text>
          </View>
        </View>
      </View>
      )
    </DocumentPage>
  );
};
export default BillingReportPdf;

const getCurrencyRate = (
  code: string,
  availableCurrencies: AvailableCurrency[]
) => {
  const currency = availableCurrencies.find((item) => item.code === code);
  return currency?.exchangeRate || 1;
};

const calculatePaymentDiff = (
  totalToPay: PriceInvoiceInterface[] = [],
  payments: PriceInvoiceInterface[] = [],
  availableCurrencies: AvailableCurrency[],
  mainCurrencyBusiness: string
) => {
  let paymentState: (PriceInvoiceInterface & { diff: number })[] =
    totalToPay.map((item) => ({ ...item, diff: item.amount }));
  let remain = 0;
  //match same currencies ------------------
  payments.forEach((payment) => {
    const idx = paymentState.findIndex(
      (toPay) => toPay.codeCurrency === payment.codeCurrency
    );
    if (idx !== -1) {
      const matchCurrency = paymentState[idx];
      //Fixed temp redondeo
      const diff = roundToTwoDecimalDow(payment.amount - matchCurrency.diff);
      if (diff > 0) {
        matchCurrency.diff = 0;
        const sum = roundToTwoDecimalDow(
          mathOperation(
            diff,
            getCurrencyRate(payment.codeCurrency, availableCurrencies),
            "multiplication",
            2
          )
        );
        remain = mathOperation(remain, sum, "addition");
      } else {
        matchCurrency.diff = Math.abs(diff);
      }
      paymentState.splice(idx, 1, matchCurrency);
    } else {
      const sum = mathOperation(
        payment.amount,
        getCurrencyRate(payment.codeCurrency, availableCurrencies),
        "multiplication"
      );
      remain = mathOperation(remain, sum, "addition");
      //remain += payment.amount * getCurrencyRate(payment.codeCurrency);
    }
  });
  //------------------------------------------------------------

  //complete with remain (first not main currencies) ------------------------
  while (remain > 0) {
    //find non zero & not null differences & not mainCurrency -------------------------
    const idx = paymentState.findIndex(
      (item) =>
        ![0, null].includes(item.diff) &&
        item.codeCurrency !== mainCurrencyBusiness
    );
    if (idx !== -1) {
      const notMainOrNull = paymentState[idx];
      const remainConverted =
        remain /
        getCurrencyRate(notMainOrNull.codeCurrency, availableCurrencies);
      const diff = remainConverted - notMainOrNull.diff!;
      if (diff > 0) {
        notMainOrNull.diff = 0;
        remain =
          diff *
          getCurrencyRate(notMainOrNull.codeCurrency, availableCurrencies);
        totalToPay.splice(idx, 1, notMainOrNull);
      } else {
        remain = 0;
        notMainOrNull.diff = Math.abs(diff);
        totalToPay.splice(idx, 1, notMainOrNull);
        break;
      }
    }

    //-------------------------------------------------------------------

    //find null difference & notMainCurrency ----------------------------------------------
    const diffIsNull = paymentState.find(
      (item) => item.diff === null && item.codeCurrency !== mainCurrencyBusiness
    );
    if (diffIsNull) {
      const remainConverted =
        remain / getCurrencyRate(diffIsNull.codeCurrency, availableCurrencies);
      const diff = remainConverted - diffIsNull.amount;
      if (diff > 0) {
        diffIsNull.diff = 0;
        remain =
          diff * getCurrencyRate(diffIsNull.codeCurrency, availableCurrencies);
      } else {
        remain = 0;
        diffIsNull.diff = Math.abs(diff);
        break;
      }
    }

    //-------------------------------------------------------------------------------------

    //find mainCurrency ------------------------------------------------------------------
    const mainCurrency = paymentState.find(
      (item) => item.codeCurrency === mainCurrencyBusiness && item.diff !== 0
    );
    if (mainCurrency) {
      const remainConverted =
        remain /
        getCurrencyRate(mainCurrency.codeCurrency, availableCurrencies);
      const diff = remainConverted - (mainCurrency.diff ?? mainCurrency.amount);
      if (diff > 0) {
        mainCurrency.diff = 0;
        remain =
          diff *
          getCurrencyRate(mainCurrency.codeCurrency, availableCurrencies);
      } else {
        remain = 0;
        mainCurrency.diff = Math.abs(diff);
        break;
      }
    } else {
      break;
    }
    //-----------------------------------------------------------------------------------
  }
  //--------------------------------------------------------------------------
  //Complete null diff with amount---------------------------------------------
  paymentState = paymentState.map((item) =>
    item.diff === null ? { ...item, diff: item.amount } : item
  );
  //---------------------------------------------------------------------------
  return paymentState
    .filter((item) => item.diff !== 0)
    .map((itm) => ({
      amount: roundToTwoDecimal(itm.diff!),
      codeCurrency: itm.codeCurrency,
    }));
};
