import { useState } from "react";
import query from "./APIServices";
import useServer from "./useServerMain";
import {
  GeneralAreaIncome,
  ProdInventoryByContainerInterface,
  MovementInventoryReportInterface,
  ProcessedProductsDataInterface,
  GrossSalesByContainerInterface,
} from "../interfaces/ServerInterfaces";
import { exportExcel, generateUrlParams } from "../utils/helpers";
import { printPriceWithCommasAndPeriods } from "../utils/functions";
import { useAppSelector } from "../store/hooks";

const useServerReports = () => {
  const { business } = useAppSelector((state) => state.init);

  const { costCurrency } = business!;

  const [isFetching, setIsFetching] = useState<boolean>(false);
  // @ts-ignore
  const [financialReport, setFinancialReport] =
    useState<GeneralAreaIncome | null>(null);

  const [processedProductsData, setProcessedProductsData] = useState<
    ProcessedProductsDataInterface[] | null
  >(null);

  const [prodInventoryByContainerData, setProdInventoryByContainerData] =
    useState<ProdInventoryByContainerInterface[] | null>(null);

  const [grossSalesByContainersData, setGrossSalesByContainersData] = useState<
    GrossSalesByContainerInterface[] | null
  >(null);

  const [costAsset, setCostAsset] = useState<any[] | null>(null);

  const [MovementInventoryReport, setMovementInventoryReport] = useState<
    MovementInventoryReportInterface[] | null
  >(null);

  const { manageErrors } = useServer();

  const getFinancialReport = async (
    filter?: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/financial/general`, filter!)
      .then((resp) => {
        //@ts-ignore
        setFinancialReport(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getSummaryOfProcessedProducts = async (
    data: Record<string, string | number | boolean | null>,
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/production/processed`, data)
      .then((resp) => {
        setProcessedProductsData(resp.data);
        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getCostAsset = async (
    data: Record<string, string | number | boolean | null>,
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/assets/cost`, data)
      .then((resp) => {
        setCostAsset(resp.data);
        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getStockInventoryFromEcoCycles = async (
    data: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .get(`/report/stock/ecocycle/period-inventory${generateUrlParams(data)}`)
      .then((resp) => {
        setMovementInventoryReport(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getProdInventoryByContainerReport = async (buyedreceiptId: number) => {
    setIsFetching(true);

    await query
      .get(`/report/buyedreceipt/${buyedreceiptId}/inventory`)
      .then((resp) => {
        setProdInventoryByContainerData(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const exportGrossSalesByContainersReport = async (
    data: GrossSalesByContainerInterface[],
    fileName: string,
    closeModal: Function
  ) => {
    setIsFetching(true);

    const dataToExport: Record<string, string | number>[] = data.map((item) => {
      return {
        "Nombre del producto": item.productName ?? "",
        "Cantidad vendida": item.quantitySales ?? "-",
        "Costo total": printPriceWithCommasAndPeriods(item.totalCost) + " " + costCurrency,
        "Precio total de ventas": item.totalSalePrices.map(elem => {
          return printPriceWithCommasAndPeriods(elem.amount) + " " + elem.codeCurrency
        }).join(", "),
        "Cantidad disponible": item.availability ?? "",
        "Nombre de la carga": item.buyedReceiptName ?? "-",
      };
    });

    exportExcel(dataToExport, fileName);

    closeModal();

    setIsFetching(false);
  };

  const getGrossSalesByContainersReport = async (
    data: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .get(`/report/buyedreceipt/selledProducts${generateUrlParams(data)}`)
      .then((resp) => {
        setGrossSalesByContainersData(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const exportProdInventoryByContainerReport = async (
    data: ProdInventoryByContainerInterface[],
    fileName: string,
    closeModal: Function
  ) => {
    setIsFetching(true);

    const dataToExport: Record<string, string | number>[] = data.map((item) => {
      return {
        "Nombre del producto": item.productName,
        "Costo unitario":
          printPriceWithCommasAndPeriods(item.unitaryCost) + " " + costCurrency,
        "Cantidad inicial": item.entryQuantity,
        "Cantidad actual": item.availableQuantity,
        "Precio unitario de venta": item.salesPrices
          ?.map(
            (elem) =>
              printPriceWithCommasAndPeriods(elem.amount) +
              " " +
              elem.codeCurrency
          )
          .join(", "),
        "Total vendido": item.quantitySales ?? 0,
        "Total vendido en moneda de costo":
          printPriceWithCommasAndPeriods(
            item.totalSalesInCostCurrency?.amount ?? 0
          ) +
          " " +
          item.totalSalesInCostCurrency?.codeCurrency,
        Ganancia: printPriceWithCommasAndPeriods(item.profit.amount),
      };
    });

    exportExcel(dataToExport, fileName);

    closeModal();

    setIsFetching(false);
  };

  return {
    isFetching,
    financialReport,
    getFinancialReport,
    processedProductsData,
    getSummaryOfProcessedProducts,
    getStockInventoryFromEcoCycles,
    MovementInventoryReport,
    costAsset,
    getCostAsset,
    getProdInventoryByContainerReport,
    prodInventoryByContainerData,
    exportProdInventoryByContainerReport,
    exportGrossSalesByContainersReport,
    getGrossSalesByContainersReport,
    grossSalesByContainersData,
  };
};

export default useServerReports;
